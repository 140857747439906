<template>
    <div class="container-form">
        <template v-if="loaded">
            <div class="form-tbf">
                <div class="input-group w-100">
                    <div class="add-task add-file">
                        <div class="header-input-file">
                            <div class="main-input file-group">
                                <button class="btn-tbf blue upload-btn" onclick="document.getElementById('otherFile').click();">
                                    <span class="text">{{ $t('create-user.add-file') }}</span>
                                </button>
                                <div class="file-name">{{ fileUpload ? fileUpload.name : '' }}</div>
                                <input style="display:none" id="otherFile" type="file" v-on:change="onFileChange">
                            </div>

                            <div class="check-expire-file" v-if="fileUpload || updateFile !== false">
                                <div class="checkboxes-group">
                                    <label class="checkbox-tbf no-margin">
                                        <span class="text-checkbox">{{ $t('create-user.file-expire') }}</span>
                                        <input type="checkbox" value="1" v-model="fileExpire">
                                        <span class="checkmark"></span>
                                    </label>						
                                </div>
                            </div>
                        </div>

                        <div class="inputs-group-expire" v-if="fileExpire">
                            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                                <div class="label-header">
                                    <label class="label">{{ $t('create-user.date-file-expire') }}</label>
                                    <div v-if="$v.fileDateExpire.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-calendar /></div>
                                    <div class="icon-right" v-if="Object.keys(fileDateExpire).length" @click.stop="fileDateExpire = {}"><icon-close class="icon-clear" /></div>
                                    <FunctionalCalendar
                                    ref="CalendarDateOfEmployment"
                                    v-model="fileDateExpire"
                                    class="calendar-tbf"
                                    :configs="{...calendarConfigs, disabledDates: ['beforeToday'], enabledDates: ['afterToday']}"
                                    >
                                        <template v-slot:datePickerInput="props">
                                            <input
                                            class="vfc-single-input custom-input-picker"
                                            type="text"
                                            :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                                            v-if="Object.keys(fileDateExpire).length"/>

                                            <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-file-expire') }}</div>
                                        </template>
                                    </FunctionalCalendar>
                                </div>
                            </div>

                            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                                <div class="label-header">
                                    <label class="label">{{ $t('create-user.file-users') }}</label>
                                    <div v-if="$v.fileExpireUsers.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-people /></div>
                                    <div class="icon-right" v-if="fileExpireUsers.length" @click.stop="fileExpireUsers = []"><icon-close class="icon-clear" /></div>
                                    <multiselect 
                                    class="hide-tags  multiselect_checkbox"
                                    v-bind:class="{populate: fileExpireUsers.length}"
                                    v-model="fileExpireUsers"
                                    :options="optionsUsers"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    :multiple="true" 
                                    :close-on-select="false"
                                    track-by="id" 
                                    label="name"
                                    >
                                        <template slot="placeholder" slot-scope="props">
                                            <span class="text">
                                                {{ $t('create-user.choose-file-users') }}
                                            </span>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                            <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="checkbox">
                                                <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                        <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                        <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>

                        <div class="actions" v-if="updateFile !== false">
                            <button class="btn-tbf white" @click="cancelEdit"><span class="text">{{ $t('general.cancel') }}</span></button>
                            <button class="btn-tbf blue" @click="updateFileFunction"><span class="text">{{ $t('general.update') }}</span></button>
                        </div>
                        <div class="actions" v-else-if="fileUpload">
                            <button class="btn-tbf white" @click="clearFile"><span class="text">{{ $t('general.cancel') }}</span></button>
                            <button class="btn-tbf blue" @click="addFile"><span class="text">{{ $t('create-user.upload') }}</span></button>
                        </div>
                    </div>

                    <div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>
                </div>

                <div v-if="savedFiles.length > 0" class="input-group w-100">
                    <div class="textarea-selected-files">
                        <div v-for="file, index in savedFiles" class="file-item">
                            <span class="name">
                                <icon-file class="icon-file" /> {{ file.name }}
                                <button class="edit" @click="editFile(file, index)"><icon-edit /></button>
                                <button class="remove" @click="deleteFile(file, index)"><icon-close /></button>
                            </span>
                            <div class="expire-date" v-if="file.expire_date">
                                <span>{{ $t('create-user.date-file-expire') }} {{ file.expire_date | moment('DD MMM YYYY') }}</span>
                            </div>
                            <div class="expire-date" v-else>
                                <span>{{ $t('create-user.not-file-expire') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-submit">
                <div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
                <div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>
    
                <div class="actions-steps">
                    <button class="btn-tbf white only-icon prev" @click="changeStepFunction('prevStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                </div>
    
                <div class="action-form">
                    <button id="buttonSubmit" class="btn-tbf blue" @click="saveAction()">
                        <div class="loader-spin" v-if="loadedSubmit"></div>
                        <span class="text" v-else>{{ $t('general.update') }}</span>
                    </button>
                </div>
            </div>
        </template>
        <LoaderStepDocuments v-else />
    </div>
</template>

<script>
import IconClose from '@/components/Icons/Close'
import IconArrow from '@/components/Icons/AngleRight'
import IconPeople from '@/components/Icons/User'
import IconEdit from '@/components/Icons/Edit'
import IconCalendar from '@/components/Icons/Calendar'
import IconFile from '@/components/Icons/FilePdf'
import LoaderStepDocuments from '@/components/PagesLoaders/CreateUserStepDocuments'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    components: {
        IconClose,
        IconArrow,
        IconPeople,
        IconEdit,
        IconCalendar,
        IconFile,
        LoaderStepDocuments
    },
    props: {
        user: Object
    },
    data() {
        return {
            loaded: false,
            loadedSubmit: false,
            error_message: '',
            success_message: '',
            errorsBe: [],
            initialData: '{}',
            
            fileUpload: '',
            fileExpire: false,
            fileDateExpire: {},
            fileExpireUsers: [],
            updateFile: false,
            savedFiles: [],
            awaitingFilesForRemove: [],
            fileError: '',
            calendarConfigs: {
                dateFormat: 'yyyy-mm-dd',
                isDatePicker: true,
                isDateRange: false,
                isModal: true,
                isAutoCloseable: true,
                changeMonthFunction: true,
                changeYearFunction: true
            },
            optionsUsers: [],
        }
    },
    validations: {
        fileExpireUsers: { required: requiredIf(function (model) {
            return this.fileExpire
        })},
        fileDateExpire: { 
            selectedDate: { required: requiredIf(function (model) {
                return this.fileExpire
            })}
        },
    },
    beforeDestroy() {
        this.$root.$off("user_form_change_step");
    },
    async mounted() {
        await this.getFilters();

        await this.$nextTick(() => {
            this.populateUserData();
        });
        
        this.$root.$on('user_form_change_step', (modeSave, changeData) => {
            if(modeSave == 'save') {
                this.saveAction(changeData);
            } else {
                if(!changeData.step) {
                    this.$emit(changeData.direction);
                } else {
                    this.$emit('goToStep', changeData.step)
                }
            }
        });
    },
    methods: {
        showModal(type, data = false){
            this.$root.$emit('open_modal', type, data);
        },
        async getFilters(){
            var filtersDB = {
                users: true,
            }

            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
            .then(({data}) => {
                this.optionsUsers = data.data.users;
            }).catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
        },
        populateUserData() {
            if(this.user.files.length){
                this.user.files.map((el) => {
                    this.savedFiles.push({name: el.filename, id: el.id, expire_date: el.expire_date, emails: el.emails})
                })
            }
            
            this.$nextTick(() => {
                this.loaded = true;

                var currentData = JSON.parse(this.initialData);
                currentData.savedFiles = this.savedFiles.length ? this.savedFiles : '';
                currentData.awaitingFilesForRemove = '';

                this.initialData = JSON.stringify(currentData);
            });
        },
        async saveAction(changeStep = false, withRedirect= false) {
            var btnSubmit = document.getElementById('buttonSubmit');
            btnSubmit.disabled = true;
            this.loadedSubmit = true;

            this.error_message = '';

            this.$v.$touch()
            if(!this.$v.$invalid){
                // Object form data for organigram
                let objDataORG = new FormData();
				objDataORG.append('user_id', this.user.id);
            
                for( var i = 0; i < this.savedFiles.length; i++ ){
					if(this.savedFiles[i]){
						let fileItem = this.savedFiles[i];
						
						if(fileItem.file){ objDataORG.append('files[' + i + '][file]', fileItem.file); }
						if(fileItem.id){ objDataORG.append('files[' + i + '][id]', fileItem.id); }
						if(fileItem.expire_date){
							objDataORG.append('files[' + i + '][expire_date]', fileItem.expire_date);
							objDataORG.append('files[' + i + '][emails]', fileItem.emails.join(","));
						}
					}
				}

				if(this.awaitingFilesForRemove.length){
					for( var i = 0; i < this.awaitingFilesForRemove.length; i++ ){
						let fileRemoveId = this.awaitingFilesForRemove[i];
						objDataORG.append('remove_files_ids[' + i + ']', fileRemoveId);
					}
				}

                this.updateUserOrganigram(objDataORG, changeStep, withRedirect)
            } else {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;
            }
        },
        updateUserOrganigram(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.post('user-files/store', objData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
            .then(({data}) => {
                this.$parent.userData = data.data;
                if(this.user.id == this.$auth.user().id){ this.$auth.fetch() }
                
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                this.success_message = this.$t('create-user.success_update_msg');
                setTimeout(() => {
                    this.success_message = '';
                }, 3000);

                this.savedFiles = [];
                this.user.files = data.data.files;
                this.user.files.map((el) => {
                    this.savedFiles.push({name: el.filename, id: el.id, expire_date: el.expire_date, emails: el.emails})
                });

                if(changeStep) {
                    this.$nextTick(() => {
                        if(!changeStep.step) {
                            this.$emit(changeStep.direction);
                        } else {
                            this.$emit('goToStep', changeStep.step)
                        }
                    });
                }

                if(withRedirect) {
                    // if(this.selectedVacantJob) {
                        // this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                    // } else {
                        if(this.$route.query.department_id){
                            this.$router.push({name: 'company'})
                        }else{
                            this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                        }
                    // }
                }
            }).catch(error =>{
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 413){
                        this.error_message = this.$t('error.too_large')
                    } else if(error.response.status == 500){
                        this.error_message = this.$t('error.500')
                    }
                }, 300)
            })
            .finally(() => { 
                this.awaitingFilesForRemove = [];

                var currentData = JSON.parse(this.initialData);
                currentData.savedFiles = this.savedFiles.length ? this.savedFiles : '';
                currentData.awaitingFilesForRemove = '';

                this.initialData = JSON.stringify(currentData);
            })
        },
        changeStepFunction(direction, stepNo = false) {

            // Create a JSon stringify with current data form
            var currentObject = JSON.stringify({
                savedFiles: this.savedFiles.length ? this.savedFiles : '',
                awaitingFilesForRemove: this.awaitingFilesForRemove.length ? this.awaitingFilesForRemove : '',
            });

            // Check diference between initial data and current
            if(this.initialData === currentObject || !this.loaded) { 
                if(direction) { 
                    this.$emit(direction);
                } else if(stepNo) {
                    this.$emit('goToStep', stepNo)
                }
            } else {
                this.$root.$emit('open_modal', 'confirm_change_step', { direction: direction, step: stepNo });
            }
        },
        onFileChange(e) {
            this.fileError = ''

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.fileUpload = files[0]
        },
        addFile(){
            this.$v.fileDateExpire.$touch()
            this.$v.fileExpireUsers.$touch()

            if(!this.$v.fileDateExpire.$error && !this.$v.fileExpireUsers.$error){
                if(this.fileUpload){
                    if(this.fileUpload.size <= 24999999){
                        let objectFile = {
                            file: this.fileUpload,
                            name: this.fileUpload.name,
                            expire_date: this.fileExpire ? this.fileDateExpire.selectedDate : "",
                            emails: this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : ""
                        }

                        this.savedFiles.push(objectFile)
                        this.clearFile()
                    }else {
                        this.fileError = this.$t('validator.file_size')
                    }
                }
            }
        },
        editFile(fileItem, index){
            this.updateFile = index
            this.fileUpload = { name: fileItem.name }
            if(fileItem.expire_date){
                this.fileExpire = true
                this.fileDateExpire = {
                    selectedDate: fileItem.expire_date,
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: '',start: ''}
                }
                fileItem.emails.map((email) => {
                    this.fileExpireUsers.push(this.optionsUsers.find(el => el.email == email))
                })
            }
        },
        cancelEdit(){
            this.updateFile = false
            this.clearFile()
        },
        updateFileFunction(){
            if(this.fileUpload.size){
                if(this.fileUpload.size <= 24999999){
                    this.savedFiles[this.updateFile].file = this.fileUpload;
                    this.savedFiles[this.updateFile].name = this.fileUpload.name;
                    this.savedFiles[this.updateFile].expire_date = this.fileExpire ? this.fileDateExpire.selectedDate : "";
                    this.savedFiles[this.updateFile].emails = this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : "";
                }else{
                    this.fileError = this.$t('validator.file_size')
                }
            }else{
                this.savedFiles[this.updateFile].name = this.fileUpload.name;
                this.savedFiles[this.updateFile].expire_date = this.fileExpire ? this.fileDateExpire.selectedDate : "";
                this.savedFiles[this.updateFile].emails = this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : "";
            }

            this.cancelEdit()
        },
        clearFile(){
            document.getElementById("otherFile").value = "";
            this.fileUpload = ''
            this.fileExpire = false
            this.fileDateExpire = {}
            this.fileExpireUsers = []
        },
        deleteFile(file, index){
            if(file.id){
                this.awaitingFilesForRemove.push(file.id)
            }
            this.savedFiles.splice(index, 1)
        },
    }
}
</script>